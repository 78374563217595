import * as React from "react"
import Layout from '../components/layout'
import Seo from "../components/seo"
import { Link } from 'gatsby'

const SiteMap = () => {
	const TheTitle = "Sitemap for Garden Escape"
	const TheDescription = "A quick, hard sitemap"
	const TheCanonical = "https://gardenescape.ca/sitemap/"
	return (
		<Layout>
			<Seo
				title={TheTitle}
				description={TheDescription}
				canonical={TheCanonical} />

			<div className="py-8">
				<div aria-labelledby="sitemap-title" className="mainWrapper my-8">
					<div className="my-0 mx-auto flex flex-col">
						<h1 id="sitemap-title" className="pb-0 mb-2 text-left">
							SITEMAP FOR GARDEN ESCAPE
						</h1>
						<p className=" mt-0 pt-0">
							You really shouldn't have landed here because this is a sitemap! Please pick a link from below!
						</p>

						<div className="">
							<ul>
								<li>
									<Link to="/" title="Garden Escape Landscaping Home">Home</Link>
								</li>
								<li>
									<Link to="/services/" title="lawn mowing services">Services</Link>
									<ul className="ml-4">
										<li>
											<Link to="/services/lawn-mowing/" title="lawn mowing services">Lawn Mowing</Link>
										</li>
										<li>
											<Link to="/services/lawn-fertilizing/" title="lawn fertilizing services">Lawn
												Fertilizing</Link>
										</li>
										<li>
											<Link to="/services/yard-cleanup/" title="yard cleanup services">Seasonal Yard
												Cleanup</Link>
										</li>
										<li>
											<Link to="/services/shrub-pruning/" title="hedge and shrub pruning services">Hedge &
												Shrub Pruning</Link>
										</li>
										<li>
											<Link to="/services/lawn-aeration/" title="Lawn Aeration services">Lawn
												Aeration</Link>
										</li>
										<li>
											<Link to="/services/power-washing/" title="Power Raking services">Power Raking</Link>
										</li>
										<li>
											<Link to="/services/power-washing/" title="Power Washing services">Power
												Washing</Link>
										</li>
										<li>
											<Link to="/services/junk-removal/" title="Junk Removal services">Junk Removal</Link>
										</li>
										<li>
											<Link to="/services/hardscaping/" title="hardscape and hardscaping services">Hardscaping</Link>
										</li>
									</ul>
								</li>
								<li>
									<Link to="/services/hardscaping/" title="contact garden escape landscaping">Contact</Link>
								</li>

							</ul>
						</div>
					</div>
				</div>
			</div>

		</Layout>
	)
}

export default SiteMap